/*
  IMPORTANT NOTE!:
  .css files must be avoided! This file was created exclusively for the use of Google Maps,
  as the library does not allow the use of useStyles.

  Under normal circumstances we shouldn't create files like this!
*/
.marker-label {
  padding: 5px 10px;
  background-color: white;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  line-height: 150%;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 800;
  font-size: 16px;
  height: 30px;
}

.marker-label::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid white;
  box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.08);
}

.active {
  background: linear-gradient(135deg, #00baff, #007cff);
  color: white !important;
}

.active::after {
  border-top: 10px solid #007cff;
}
